// import React from 'react';
// import logo from './logo.svg';
// import '../App.css';
// import Nav from '../components/nav.component';
// import Header from '../components/header.component'
// import About from '../components/about.component'
// import Footer from '../components/footer.component'
// import {Row,Col,Container} from 'reactstrap';
//
// function Home() {
//     return (
//         <div className="App">
//             <Nav/>
//             <Header/>
//             <About/>
//             <Footer/>
//
//         </div>
//     );
// }
//
// export default Home;

import React from 'react';
import logo from './logo.svg';
import '../App.css';
import Nav from '../components/nav.component';
import Footer from '../components/footer.component'
import Articles from '../components/articles.component'
import {Row,Col,Container} from 'reactstrap';
import {Table} from "react-bootstrap";
import {Colors} from '../constants/colors'
// @ts-ignore
import AOS from 'aos';
import 'aos/dist/aos.css';

function Home() {
    AOS.init({
        duration: 2000,
    })
    // const mystyle = {
    //     homeBtn: "green"
    // };
    return (

        <div className="Home">

            {/*<Nav/>*/}
            <div style={{position:'relative'}} >
                <Row>
                    <Col md="4" xs="12" style={{alignItems:'center'}}>
                        <div data-aos="fade-up" data-aos-offset="100" style={{alignContent:"center",alignItems:'center',display:"flex",justifyContent:"center",padding:100}}>
                            <img src="assets/img/cover_01_02.png" width='300px' style={{boxShadow: '0px 0px 6px #115b84'}}/>
                        </div>
                    </Col>
                    <Col md="5" xs="12" style={{background:"white"}}>
                        <Container>
                            <Row >
                                <Container>
                                    <div data-aos="fade-up" data-aos-offset="100" style={{
                                        fontSize: 25,
                                        textAlign: 'left',
                                        wordSpacing: 3,
                                        paddingTop: 75,
                                        color: 'black',
                                        fontWeight:'bold'
                                        // marginTop:10
                                        // fontFamily:'Itim',
                                    }}>
                                        ASIAN JOURNAL OF POLITICS AND SOCIETY (AJPS)
                                    </div>

                                    <div data-aos="fade-up" data-aos-offset="100" style={{
                                        fontSize: 15,
                                        textAlign: 'left',
                                        wordSpacing: 3,
                                        paddingTop: 5,
                                        paddingBottom: 5,
                                        color: 'black'
                                        // marginTop:10
                                        // fontFamily:'Itim',
                                    }}>
                                        The Asian Journal of Politics and Society (AJPS) is an international open-access, peer-reviewed, bi-annual, refereed, and quality-guaranteed e-journal that is published by the Department of Social Sciences, Faculty of Social Sciences and Languages, Sabaragamuwa University of Sri Lanka.
                                    </div>

                                    <div data-aos="fade-up" data-aos-offset="100" style={{
                                        fontSize: 15,
                                        textAlign: 'left',
                                        wordSpacing: 3,
                                        paddingTop: 5,
                                        paddingBottom: 15,
                                        color: 'black'
                                        // marginTop:10
                                        // fontFamily:'Itim',
                                    }}>
                                        The journal’s main aim is to provide a platform for academics, scholars, resource persons and practitioners to publish their research findings with the rest of the world in order for informed decisions to be taken to improve society as a whole. The journal welcomes articles from researchers interested in theoretical developments/debates, results of research that advance understanding of fundamental social and political processes, important methodological innovations, empirical analysis, policy implementation, and pragmatic themes and issues in Anthropology, Sociology, Social Work, Political Science, International Relations and Peace Studies. This journal welcomes and is willing to publish articles that are both technically sound and driven by sound reasoning.
                                    </div>
                                </Container>

                            </Row>
                        </Container>

                    </Col>
                    <Col md="3" xs="12" style={{background:"white"}}>
                        <Container>
                            <div data-aos="fade-up" data-aos-offset="100" style={{
                                fontSize: 15,
                                textAlign: 'left',
                                wordSpacing: 3,
                                paddingTop: 80,
                                paddingBottom: 2,
                                color: 'black'
                                // marginTop:10
                                // fontFamily:'Itim',
                            }}>
                                ISSN: 2989-011x
                            </div>
                            {/*<div data-aos="fade-up" data-aos-offset="100"*/}
                            {/*     style={{*/}
                            {/*         fontSize: 15,*/}
                            {/*         textAlign: 'left',*/}
                            {/*         wordSpacing: 3,*/}
                            {/*         paddingTop: 5,*/}
                            {/*         paddingBottom: 15,*/}
                            {/*         color: 'black'*/}
                            {/*         // marginTop:10*/}
                            {/*         // fontFamily:'Itim',*/}
                            {/*     }}>*/}
                            {/*    e-ISSN: 2989-0527*/}
                            {/*</div>*/}
                            <div data-aos="fade-up" data-aos-offset="100"
                                 style={{
                                     fontSize: 15,
                                     textAlign: 'left',
                                     wordSpacing: 3,
                                     paddingTop: 5,
                                     paddingBottom: 15,
                                     color: 'black'
                                     // marginTop:10
                                     // fontFamily:'Itim',
                                 }}>
                                Publisher: Department of Social Sciences, Faculty of Social Sciences and Languages, Sabaragamuwa University of Sri Lanka
                            </div>
			    
                        </Container>

                    </Col>
                </Row>
                <Row style={{paddingBottom:50}}>
		    <Col md="1" xs="12" style={{alignItems:'center'}}>
                    </Col>

                    <Col md="6" xs="12" style={{alignItems:'center'}}>
                        <Articles/>
                    </Col>
		    <Col md="2" xs="12" style={{alignItems:'center'}}>

                    </Col>

		    <Col md="3" xs="12" style={{alignItems:'center'}}>
                        <div data-aos="fade-up" data-aos-offset="100" style={{
                    fontSize: 25,
                    textAlign: 'left',
                    wordSpacing: 3,
                    paddingTop: 5,
                    paddingBottom: 15,
                    color: 'black',
                    fontWeight:"bold"
                    // marginTop:10
                    // fontFamily:'Itim',
                }}>
                    Volume 01 | Issue 02
                </div>

		<div data-aos="fade-up" data-aos-offset="100" style={{
                    fontSize: 15,
                    textAlign: 'left',
                    wordSpacing: 3,
                    paddingTop: 5,
                    paddingBottom: 0,
                    color: 'black',
                    fontWeight:"bold"
                    // marginTop:10
                    // fontFamily:'Itim',
                }}>
                    <a style={{textDecoration:'none'}} href='assets/articles/2023-2-AJPS.pdf' target='_blank' rel="noreferrer">
                        Full Paper
                    </a>
                </div>

                <hr data-aos="fade-up" data-aos-offset="100" />
                <div data-aos="fade-up" data-aos-offset="100" style={{
                    fontSize: 15,
                    textAlign: 'left',
                    wordSpacing: 3,
                    paddingTop: 5,
                    paddingBottom: 0,
                    color: 'black',
                    fontWeight:"bold"
                    // marginTop:10
                    // fontFamily:'Itim',
                }}>
                    <a style={{textDecoration:'none'}} href='assets/articles/2023-2-AJPS-01.pdf' target='_blank' rel="noreferrer">
                        Social Connection, Stigma and Mental Health Issues: Experiences of People with Mental Health Issues in Colombo District, Sri Lanka
                    </a>
                </div>
                <div data-aos="fade-up" data-aos-offset="100" style={{
                    fontSize: 15,
                    textAlign: 'left',
                    wordSpacing: 3,
                    paddingTop: 3,
                    paddingBottom: 15,
                    color: 'black',
                    // fontWeight:"bold"
                    // marginTop:10
                    // fontFamily:'Itim',
                }}>
                    K.A. Epasinghe
                </div>


                <hr data-aos="fade-up" data-aos-offset="100" />
                <div data-aos="fade-up" data-aos-offset="100" style={{
                    fontSize: 15,
                    textAlign: 'left',
                    wordSpacing: 3,
                    paddingTop: 5,
                    paddingBottom: 0,
                    color: 'black',
                    fontWeight:"bold"
                    // marginTop:10
                    // fontFamily:'Itim',
                }}>
                    <a style={{textDecoration:'none'}} href='assets/articles/2023-2-AJPS-02.pdf' target='_blank'  rel="noreferrer">
                        Study on Factors Identification on Nutritional Status Among Children: An Econometrics Analysis
                    </a>
                </div>
                <div data-aos="fade-up" data-aos-offset="100" style={{
                    fontSize: 15,
                    textAlign: 'left',
                    wordSpacing: 3,
                    paddingTop: 3,
                    paddingBottom: 15,
                    color: 'black',
                    // fontWeight:"bold"
                    // marginTop:10
                    // fontFamily:'Itim',
                }}>
                    W.R.K.M. Wimalasiri
                </div>


                <hr data-aos="fade-up" data-aos-offset="100" />
                <div data-aos="fade-up" data-aos-offset="100" style={{
                    fontSize: 15,
                    textAlign: 'left',
                    wordSpacing: 3,
                    paddingTop: 5,
                    paddingBottom: 0,
                    color: 'black',
                    fontWeight:"bold"
                    // marginTop:10
                    // fontFamily:'Itim',
                }}>
                    <a style={{textDecoration:'none'}} href='assets/articles/2023-2-AJPS-03.pdf' target='_blank'  rel="noreferrer">
                        Nature and the Relevance of the World Balance of Power after World War II
                    </a>
                </div>
                <div data-aos="fade-up" data-aos-offset="100" style={{
                    fontSize: 15,
                    textAlign: 'left',
                    wordSpacing: 3,
                    paddingTop: 3,
                    paddingBottom: 15,
                    color: 'black',
                    // fontWeight:"bold"
                    // marginTop:10
                    // fontFamily:'Itim',
                }}>
                    H.E.N. Priyadarshani
                </div>

                <hr data-aos="fade-up" data-aos-offset="100" />
                <div data-aos="fade-up" data-aos-offset="100" style={{
                    fontSize: 15,
                    textAlign: 'left',
                    wordSpacing: 3,
                    paddingTop: 5,
                    paddingBottom: 0,
                    color: 'black',
                    fontWeight:"bold"
                    // marginTop:10
                    // fontFamily:'Itim',
                }}>
                    <a style={{textDecoration:'none'}} href='assets/articles/2023-2-AJPS-04.pdf' target='_blank'  rel="noreferrer">
                        Identifying Social Risk Factors of Child Undernutrition in the Nuwara-Eliya District, Sri Lanka                    
		    </a>
                </div>
                <div data-aos="fade-up" data-aos-offset="100" style={{
                    fontSize: 15,
                    textAlign: 'left',
                    wordSpacing: 3,
                    paddingTop: 3,
                    paddingBottom: 15,
                    color: 'black',
                    // fontWeight:"bold"
                    // marginTop:10
                    // fontFamily:'Itim',
                }}>
                    R.M.L. Rathnayake
                </div>



                <hr data-aos="fade-up" data-aos-offset="100" />
                <div data-aos="fade-up" data-aos-offset="100" style={{
                    fontSize: 15,
                    textAlign: 'left',
                    wordSpacing: 3,
                    paddingTop: 5,
                    paddingBottom: 0,
                    color: 'black',
                    fontWeight:"bold"
                    // marginTop:10
                    // fontFamily:'Itim',
                }}>
                    <a style={{textDecoration:'none'}} href='assets/articles/2023-2-AJPS-05.pdf' target='_blank'  rel="noreferrer">
                        A Book Review of <q>Liquid Love: On the Frailty of Human Bonds</q>
                    </a>
                </div>
                <div data-aos="fade-up" data-aos-offset="100" style={{
                    fontSize: 15,
                    textAlign: 'left',
                    wordSpacing: 3,
                    paddingTop: 3,
                    paddingBottom: 15,
                    color: 'black',
                    // fontWeight:"bold"
                    // marginTop:10
                    // fontFamily:'Itim',
                }}>
                    M.N. Vineeshiya
                </div>
		<div data-aos="fade-up" data-aos-offset="100" style={{
                    fontSize: 25,
                    textAlign: 'left',
                    wordSpacing: 3,
                    paddingTop: 5,
                    paddingBottom: 15,
                    color: 'black',
                    fontWeight:"bold"
                    // marginTop:10
                    // fontFamily:'Itim',
                }}>
                    Volume 01 | Issue 01
                </div>

                {/*<hr data-aos="fade-up" data-aos-offset="100" />*/}
                {/*<div data-aos="fade-up" data-aos-offset="100" style={{*/}
                {/*    fontSize: 15,*/}
                {/*    textAlign: 'left',*/}
                {/*    wordSpacing: 3,*/}
                {/*    paddingTop: 5,*/}
                {/*    paddingBottom: 0,*/}
                {/*    color: 'black',*/}
                {/*    fontWeight:"bold",*/}
                {/*    // marginTop:10*/}
                {/*    // fontFamily:'Itim',*/}
                {/*}}>*/}
                {/*    <a style={{textDecoration:'none'}} target='_blank'>*/}
                {/*        Editorial Note*/}
                {/*    </a>*/}
                {/*</div>*/}
        
                <div data-aos="fade-up" data-aos-offset="100" style={{
                    fontSize: 15,
                    textAlign: 'left',
                    wordSpacing: 3,
                    paddingTop: 5,
                    paddingBottom: 0,
                    color: 'black',
                    fontWeight:"bold"
                    // marginTop:10
                    // fontFamily:'Itim',
                }}>
                    <a style={{textDecoration:'none'}} href='assets/articles/2023-1-AJPS.pdf' target='_blank' rel="noreferrer">
                        Full Paper
                    </a>
                </div>

                <hr data-aos="fade-up" data-aos-offset="100" />
                <div data-aos="fade-up" data-aos-offset="100" style={{
                    fontSize: 15,
                    textAlign: 'left',
                    wordSpacing: 3,
                    paddingTop: 5,
                    paddingBottom: 0,
                    color: 'black',
                    fontWeight:"bold"
                    // marginTop:10
                    // fontFamily:'Itim',
                }}>
                    <a style={{textDecoration:'none'}} href='assets/articles/2023-1-AJPS-01.pdf' target='_blank' rel="noreferrer">
                        Universal Buddhist Pantheon in Post-War Sri Lanka
                    </a>
                </div>
                <div data-aos="fade-up" data-aos-offset="100" style={{
                    fontSize: 15,
                    textAlign: 'left',
                    wordSpacing: 3,
                    paddingTop: 3,
                    paddingBottom: 15,
                    color: 'black',
                    // fontWeight:"bold"
                    // marginTop:10
                    // fontFamily:'Itim',
                }}>
                    Mahesh Premarathna
                </div>


                <hr data-aos="fade-up" data-aos-offset="100" />
                <div data-aos="fade-up" data-aos-offset="100" style={{
                    fontSize: 15,
                    textAlign: 'left',
                    wordSpacing: 3,
                    paddingTop: 5,
                    paddingBottom: 0,
                    color: 'black',
                    fontWeight:"bold"
                    // marginTop:10
                    // fontFamily:'Itim',
                }}>
                    <a style={{textDecoration:'none'}} href='assets/articles/2023-1-AJPS-02.pdf' target='_blank'  rel="noreferrer">
                        Gender Differences in the Determinants of Business Performance among Entrepreneurs of the Tourism Industry in Sri Lanka

                    </a>
                </div>
                <div data-aos="fade-up" data-aos-offset="100" style={{
                    fontSize: 15,
                    textAlign: 'left',
                    wordSpacing: 3,
                    paddingTop: 3,
                    paddingBottom: 15,
                    color: 'black',
                    // fontWeight:"bold"
                    // marginTop:10
                    // fontFamily:'Itim',
                }}>
                    Saman Handaragama
                </div>


                <hr data-aos="fade-up" data-aos-offset="100" />
                <div data-aos="fade-up" data-aos-offset="100" style={{
                    fontSize: 15,
                    textAlign: 'left',
                    wordSpacing: 3,
                    paddingTop: 5,
                    paddingBottom: 0,
                    color: 'black',
                    fontWeight:"bold"
                    // marginTop:10
                    // fontFamily:'Itim',
                }}>
                    <a style={{textDecoration:'none'}} href='assets/articles/2023-1-AJPS-03.pdf' target='_blank'  rel="noreferrer">
                        War Memories and Their Impact on the Ethnic Reconciliation Process in Sri Lanka
                    </a>
                </div>
                <div data-aos="fade-up" data-aos-offset="100" style={{
                    fontSize: 15,
                    textAlign: 'left',
                    wordSpacing: 3,
                    paddingTop: 3,
                    paddingBottom: 15,
                    color: 'black',
                    // fontWeight:"bold"
                    // marginTop:10
                    // fontFamily:'Itim',
                }}>
                    Suranjith Gunasekara
                </div>

                <hr data-aos="fade-up" data-aos-offset="100" />
                <div data-aos="fade-up" data-aos-offset="100" style={{
                    fontSize: 15,
                    textAlign: 'left',
                    wordSpacing: 3,
                    paddingTop: 5,
                    paddingBottom: 0,
                    color: 'black',
                    fontWeight:"bold"
                    // marginTop:10
                    // fontFamily:'Itim',
                }}>
                    <a style={{textDecoration:'none'}} href='assets/articles/2023-1-AJPS-04.pdf' target='_blank'  rel="noreferrer">
                        Criminalising Dissent: Sri Lanka�s Prevention of Terrorism Act and Its Use in Suppressing Social Movements - 2022                   
		    </a>
                </div>
                <div data-aos="fade-up" data-aos-offset="100" style={{
                    fontSize: 15,
                    textAlign: 'left',
                    wordSpacing: 3,
                    paddingTop: 3,
                    paddingBottom: 15,
                    color: 'black',
                    // fontWeight:"bold"
                    // marginTop:10
                    // fontFamily:'Itim',
                }}>
                    Vishanka Gunawardana
                </div>

                <hr data-aos="fade-up" data-aos-offset="100" />
                <div data-aos="fade-up" data-aos-offset="100" style={{
                    fontSize: 15,
                    textAlign: 'left',
                    wordSpacing: 3,
                    paddingTop: 5,
                    paddingBottom: 0,
                    color: 'black',
                    fontWeight:"bold"
                    // marginTop:10
                    // fontFamily:'Itim',
                }}>
                    <a style={{textDecoration:'none'}} href='assets/articles/2023-1-AJPS-05.pdf' target='_blank'  rel="noreferrer">
                        Uncovering the Methods of Operation and Funding of Armed Groups in the MENA Region: A Special Focus on Libya and Yemen

                    </a>
                </div>
                <div data-aos="fade-up" data-aos-offset="100" style={{
                    fontSize: 15,
                    textAlign: 'left',
                    wordSpacing: 3,
                    paddingTop: 3,
                    paddingBottom: 15,
                    color: 'black',
                    // fontWeight:"bold"
                    // marginTop:10
                    // fontFamily:'Itim',
                }}>
                    Mohamad Zreik
                </div>

                    </Col>
                </Row>
            </div>
            <Footer/>

        </div>
    );
}

export default Home;
